<template>
  <div class="w-full space-y-4">
    <page-title>RSS channels</page-title>
    <div class="w-full border border-green-700 p-2">
      <div class="overflow-scroll divide-y h-112">
        <div
          class="space-y-2 p-1"
          v-for="({ id, title, rss }, idx) in urls"
          :key="idx"
        >
          <div class="flex-between">
            <div>
              <div class="font-bold">{{ title || 'Unknown' }}</div>
              <div>{{ rss }}</div>
            </div>
            <c-icon
              name="x"
              :width="20"
              :height="20"
              class="cursor-pointer"
              @click="deleteUrl(id)"
            />
          </div>
        </div>
      </div>
      <div class="w-full mt-4 border-t-2">
        <c-input label="Title" class="w-64" v-model="input.title" />
        <c-input label="RSS Url" v-model="input.rss" />
        <div class="flex justify-end w-full">
          <c-btn class="ml-4 mt-2" @click="addUrl"> Add RSS </c-btn>
        </div>
      </div>
    </div>
    <page-title>Keywords</page-title>
    <div class="w-full border border-green-700 p-2">
      <div class="flex-start p-1 mb-8 h-12 w-96">
        <c-input class="w-48" v-model="input.keyword" />
        <c-btn class="ml-4 mt-2" @click="addKeyword"> Add keywords </c-btn>
      </div>
      <div class="flex space-x-4">
        <div
          class="flex-between border p-2 border-green-700"
          v-for="(keyword, idx) in keywords"
          :key="idx"
        >
          <span class="mr-4">{{ keyword }}</span>
          <c-icon
            name="x"
            :width="20"
            :height="20"
            class="cursor-pointer"
            @click="deleteKeyword(keyword)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RSS',
  data: () => ({
    input: {
      keyword: '',
      title: '',
      rss: '',
    },
    keywords: [],
    urls: [],
  }),
  methods: {
    async fetchData() {
      const { keywords, urls } = await this.$http.get(
        '/api/v1/markets/rss-config'
      )
      this.urls = urls
      this.keywords = keywords
    },
    async pushData() {
      await this.$http.post('/api/v1/markets/rss', {
        urls: this.urls || [],
        keywords: this.keywords || [],
      })
      // this.$router.go()
    },
    addUrl() {
      if (this.input.title && this.input.rss) {
        this.urls.push({
          id: Date.now(),
          title: this.input.title,
          rss: this.input.rss,
        })
        this.input.title = ''
        this.input.rss = ''
      }
      this.pushData()
    },
    deleteUrl(id) {
      this.urls = this.urls.filter((el) => el.id !== id)
      this.pushData()
    },
    deleteKeyword(keyword) {
      this.keywords = this.keywords.filter((el) => el !== keyword)
      this.pushData()
    },
    addKeyword() {
      const keyword = this.input.keyword
      if (!this.keywords.find((el) => el === keyword)) {
        this.keywords.push(keyword)
      }
      this.input.keyword = ''
      this.pushData()
    },
  },
  async created() {
    await this.fetchData()
  },
}
</script>

<style lang="css" scoped></style>
